
import {StyledFooterContainer, StyledFooterContent,SectionHyphen} from '../styledComponents/Footer.style'
import hseLogo from "../assets/images/hseLogo.png"
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

function Footer() {
    const {t} = useTranslation()

    const versionStyle = {
        fontSize:'12px',
        position: 'absolute',
        right:'10%',
    }

    return(
        <StyledFooterContainer >
            <SectionHyphen/>
           <StyledFooterContent>
               <img src={hseLogo}/>
               <span>Kontakt: <a href={"mailto:info@hse365.at"}>info@hse365.at</a></span>
               <span>Support: <a href={"mailto:customer@hse-support.com"}>customer@hse-support.com</a></span>
               <Link to={t('imprintRoute')}>{t('footerImprintText')}</Link>
           </StyledFooterContent>
            <span style={versionStyle}>Version:2.0.2</span>
        </StyledFooterContainer>
    )
}

 export default Footer;