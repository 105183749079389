import styled from "styled-components";

// const thetransition = "all .5s cubic-bezier(1,.25,0,.75) 0s";

const StyledNavbar = styled.div`

  width: 100%;
  margin: auto;

  
  width: 80%;
  margin: auto;
 

  
@media (max-width: 600px){
  width: 80%;
  position: relative;
}
`;

export const HamburgerButton = styled.img`
  font-size: 2.1rem;
  float: right;
  z-index: 2;
  position: relative;
  
@media (min-width: 600px) {
  display: none;
  
}
`;
export const MobilMenu = styled.nav`
  display: flex;
  flex-direction: column;
  background-color: lightgray;
  opacity: 0.9;
  position: absolute;
  top: 2em;
  border: 0;
  right: -2em;
  width: 40%;
  padding: 1rem 2rem;
  transform: ${({ open }) => (open ? "translateX(0%);" : "translateX(200%);")};
  transition: transform 350ms ease-in;
  
  a{
    text-decoration: none;
    text-align: right;
    color: #005faa;
  }
  
@media(min-width: 600px){
  display: none;
}
`;


export const DesktopMenu = styled.nav`
  display: flex;
  flex-direction: row;

  margin-left: 30%;
  

  width: 70%;
  float: right;
  justify-content: end;

  
 a {
   color: #005faa;
   text-decoration: none;
   padding: 0 1em 0 1em;
   position: relative;

   @media (max-width: 1110px){
     text-align: center;
     margin: auto;
     line-height: 2em;
   }
  }
  
  a::after{
    content: '';
    position: absolute;
    width: 80%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0.8em;
    left: 1em;
    background-color: #005faa;
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
  }
  
  a:nth-child(3)::after{
    content: '';
    position: absolute;
    width: 80%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0.8em;
    left: 0.5em;
    background-color: #005faa;
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
  }
  
  a:nth-child(5)::after{
    content: '';
    position: absolute;
    width: 80%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0.8em;
    left: 0.5em;
    background-color: #005faa;
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
  }

  a:nth-child(7)::after{
    content: '';
    position: absolute;
    width: 80%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0.8em;
    left: 0.5em;
    background-color: #005faa;
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
  }
  
  a:nth-child(9)::after{
    content: '';
    position: absolute;
    width: 80%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0.8em;
    left: 0.5em;
    background-color: #005faa;
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
  }

  .active {
    text-decoration: underline 2px #005faa;
    text-underline-offset: 0.54em;;
  }

  .active:hover::after{
    display: none;
  }
  
  a:hover::after{
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  
  hr{
    border: 1px solid #005faa;
    margin: auto 0;
    height: 1em;
    
    @media (max-width: 1110px){
      height: 3em;
    }
   
    
  }
  @media(max-width: 600px){
    display: none;
  }
`;

export default StyledNavbar;