
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Suspense, lazy, useEffect} from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import './i18n';
import {useTranslation} from "react-i18next";


const CompanySide = lazy( () => import( "./pages/Company"))
const ProductsSide = lazy( () => import( "./pages/Products"))
const QualitymanagementSide  = lazy( () => import( "./pages/Qualitymanagement"))
const OurHistorySide = lazy( () => import( "./pages/OurHistory"))
const  ImpressumSide = lazy( () => import( "./pages/Impressum"))
const  ZertifikateSide= lazy( () => import( "./pages/Zertifikate"))
const  PrivacySide= lazy( () => import( "./pages/Privacy"))
const  VOS365Side= lazy( () => import( "./pages/VOS365"))
const  DoQmanagerSide= lazy( () => import( "./pages/DoQmanager"))
const HealthStorageSide = lazy( () => import( "./pages/HealthStorage"))
const SoftwareValidationSide = lazy( () => import( "./pages/SoftwareValidation"))
const ConsultingAndTrainingSite = lazy( () => import( "./pages/ConsultingAndTraining"))
const CookieBanner = lazy(() => import("./components/CookieBanner"))
const Quiz = lazy(() => import("./quiz/Quiz"))
// const ContactForm = lazy(()=> import("./components/RequestForm"))




const wrapper = {
    overflow: 'hidden',
    backgroundColor: 'white',
    width:'100%',
    fontFamily:'Arial',
    fontWeight: '400',

}

const loadingScreen = {
    display: 'flex',
    marginTop: '20em',
    marginBottom: '20em',
    marginLeft: '50%',
}

function App() {
    const {t} = useTranslation();
    const { i18n} = useTranslation();

    useEffect(() => {
        const language = window.location.pathname.includes("/en") ? "en" : "de";
        i18n.changeLanguage(language);
    },[]);

  return (
  <BrowserRouter>
      <div style={wrapper}>
          <Header/>
          <Suspense fallback={<h2 style={loadingScreen}>Loading....</h2>}>
              <Routes>
                  <Route exact path={'en/Company'} element={ <CompanySide/>}/>
                  <Route exact path={'de/Unternehmen'} element={ <CompanySide/>}/>
                  <Route exact path={'/'} element={ <CompanySide/>}/>

                  <Route exact path={'en/QM'} element={<QualitymanagementSide/>}/>
                  <Route exact path={'de/QM'} element={<QualitymanagementSide/>}/>

                  <Route exact path={'de/Produkte'} element={ <ProductsSide/>}/>
                  <Route exact path={'en/Products'} element={ <ProductsSide/>}/>

                  <Route exact path={'en/History'} element={ <OurHistorySide/>}/>
                  <Route exact path={'de/Werdegang'} element={ <OurHistorySide/>}/>


                  <Route exact path={'de/Impressum'} element={ <ImpressumSide/>}/>
                  <Route exact path={'en/Imprint'} element={ <ImpressumSide/>}/>

                  <Route exact path={t('certificatesRoute')} element={ <ZertifikateSide/>}/>

                  <Route exact path={'/Datenschutzbestimmung'} element={ <PrivacySide/>}/>
                  <Route exact path={'/PrivacyPolicy'} element={ <PrivacySide/>}/>

                  <Route exact path={'de/VOS365'} element={ <VOS365Side/>}/>
                  <Route exact path={'en/VOS365'} element={ <VOS365Side/>}/>

                  <Route exact path={'de/doQ.manager'} element={ <DoQmanagerSide/>}/>
                  <Route exact path={'en/doQ.manager'} element={ <DoQmanagerSide/>}/>


                  <Route exact path={'de/HealthStorage'} element={ <HealthStorageSide/>}/>
                  <Route exact path={'en/HealthStorage'} element={ <HealthStorageSide/>}/>


                  <Route exact path={'de/SoftwareValidierung'} element={ <SoftwareValidationSide/>}/>
                  <Route exact path={'en/SoftwareValidation'} element={ <SoftwareValidationSide/>}/>

                  <Route exact path={'de/kurse&trainig'} element={ <ConsultingAndTrainingSite/>}/>
                  <Route exact path={'en/courses&trainings'} element={ <ConsultingAndTrainingSite/>}/>

                  {/*<Route exact path={'de/Kontaktformular'} element={ <ContactForm/>}/>*/}
                  {/*<Route exact path={'en/contactUs'} element={ <ContactForm/>}/>*/}

                  <Route exact path={"de/Fragebogen"} element={ <Quiz/>}/>
                  <Route exact path={"en/Questionnaire"} element={ <Quiz/>}/>
              </Routes>
          </Suspense>
          <Footer/>
          <CookieBanner/>
      </div>
  </BrowserRouter>
  );
}

export default App;
