import styled from "styled-components";

export const StyledFooterContainer = styled.div`
  background-color: rgba(0,95,170,.1);
  width: 90%;
  margin:0 auto;
<<<<<<< HEAD
=======
  position: sticky;
>>>>>>> master
  @media (max-width: 600px) {
    text-align: center;
  }
 
`;

export const StyledFooterContent = styled.div`
  //margin-left: 21.2em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 1em;
  align-items: center;
 

  a{
    text-decoration: none;
    color: black;
  }
  a:hover{
    text-decoration: underline;
    color: #005faa;
  }
  img {
    width: 10%;
    
  }
  
  @media (max-width: 600px) {
    flex-direction: column;

    span{
      //padding: 1em;
      margin-bottom: 3em;
    }
    
    a{
      margin-bottom: 2em;
    }

    img {
      width: 50%;
      //height: 100px;
      margin-bottom: 2em;
    }
  }
`;

export const SectionHyphen = styled.hr`
    margin: 0 auto;
  border: solid 1px black;
  width: 60%;
`