import styled from "styled-components";



const StyledLanguageChooser = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  height: 20px;
  width: 65px;
  text-align: center;

  .rotate-button {
    transition: transform 0.5s ease-in-out;
  }

  .rotate {
    transform: rotate(180deg);
    
  }
  
  .rotateText {
    transform: rotate(180deg);
  }
 
  
  button {
    color: #005faa;
    background-color: transparent;
    border: none;
    margin: 1px;
    font-size: 11px;
    cursor: pointer;
  }
  
  .dash {
    border-top: #005faa 1px solid;
    width: 50%;
    margin: auto;
  }
  
  .languageButtonsContainer {
    display: flex;
    flex-direction: column;
    color: #005faa;
    cursor: pointer;
    margin: 8px auto;
    font-size: 11px;
  }
  
  
  @media(max-width: 600px){
    position: absolute;
    bottom: 15px;
    left: 0px;
  
  }
  
`

export default StyledLanguageChooser;