import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import StyledLanguageChooser from '../styledComponents/LanguageChooser.style'


function LanguageChooser() {
    const { i18n } = useTranslation()
    const [currentLanguage, setCurrentLanguage] = useState('')
    const [chosenLanguage, setChosenLanguage] = useState('');
    const [isRotated, setIsRotated] = useState(false);
    const [deButtonStyle, setDEButtonStyle] = useState("deButtonClicked");
    const [enButtonStyle, setENButtonStyle] = useState("enButton");

    useEffect(() => {
        const language = window.location.pathname.includes("/en") ? "en" : "de";
        i18n.changeLanguage(language);
        if(language === "en"){
            setCurrentLanguage('en')
            setChosenLanguage('en')
        } else {
            setCurrentLanguage('de')
            setChosenLanguage('de')
        }

    }, []);

    const changeLanguageToDe = (event) => {

           if(chosenLanguage === 'en'){
               const language = event.target.value;
               i18n.changeLanguage(language);
               setChosenLanguage('de')
               setIsRotated(!isRotated);
           }

    };

    const changeLanguageToEN = (event) => {

        if(chosenLanguage === 'de') {
            const language = event.target.value;
            i18n.changeLanguage(language);
            setChosenLanguage('en')
            setIsRotated(!isRotated);
        }

    };

    return (
        <StyledLanguageChooser >
            <div className={`rotate-button ${isRotated ? 'rotate' : ''}`}>
                {currentLanguage === 'en'
            ?
                <>
                    <button className={`rotate-button ${isRotated ? 'rotateText' : ''}`}
                            onClick={changeLanguageToEN} value="en" name="language">
                        EN
                    </button>
                    <div className={'dash'}></div>
                    <button className={`rotate-button ${isRotated ? 'rotateText' : ''}`}
                            onClick={changeLanguageToDe} value="de" name="language">
                        DE
                    </button>
                </>
                :
                <>
                    <button className={`rotate-button ${isRotated ? 'rotateText' : ''}`}
                            onClick={changeLanguageToDe} value="de" name="language">
                        DE
                    </button>
                    <div className={'dash'}></div>
                    <button className={`rotate-button ${isRotated ? 'rotateText' : ''}`}
                            onClick={changeLanguageToEN} value="en" name="language">
                        EN
                    </button>
                </>
            }
            </div>
        </StyledLanguageChooser>
    );
}

export default LanguageChooser;

