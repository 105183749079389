import StyledNavbar, {DesktopMenu, HamburgerButton, MobilMenu} from "../styledComponents/Navbar.styled";
import hamburgerMenuIcon from '../assets/images/hamburgerMenu.svg'
import {useState} from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom"
import {useTranslation} from "react-i18next";
import LanguageChooser from "./LanguageChooser";

function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })

    return (
            <Link className={isActive ? "active" : ""} to={to} {...props}>
                {children}
            </Link>
    )
}


const Navbar = () => {
    const [navOpen, setNav] = useState(false);
    const {t} = useTranslation();
    return(
        <StyledNavbar>
            <HamburgerButton alt={'Hamburher Menu Icon'} src={hamburgerMenuIcon} onClick={() => {setNav(c=> !c)}} />
            <MobilMenu open={navOpen}>
                <Link to={t('homeRoute')} onClick={() =>{setNav(false)}}><p>{t('navBarHeaderCompany')}</p></Link>
                <Link to={t('qmConsultingRoute')} onClick={() =>{setNav(false)}} ><p>{t('navBarHeaderQM')}</p></Link>
                <Link to={t("productsRoute")} onClick={() =>{setNav(false)}}><p>{t('navBarHeaderProducts')}</p></Link>
                <Link to={t('ourHistoryRoute')} onClick={() =>{setNav(false)}}><p>{t('navBarHeaderHistory')}</p></Link>
                <Link to={t('quizRoute')} onClick={() =>{setNav(false)}}><p>{t('navBarHeaderQuiz')}</p></Link>
                <LanguageChooser/>
            </MobilMenu>
            <DesktopMenu>
                <CustomLink to={t('homeRoute')}><h1>{t('navBarHeaderCompany')}</h1></CustomLink>
                <hr/>
                <CustomLink to={t('qmConsultingRoute')}><h1>{t('navBarHeaderQM')}</h1></CustomLink>
                <hr/>
                <CustomLink to={t("productsRoute")}><h1>{t('navBarHeaderProducts')}</h1></CustomLink>
                <hr/>
                <CustomLink to={t('ourHistoryRoute')}><h1>{t('navBarHeaderHistory')}</h1></CustomLink>
                <hr/>
                <CustomLink to={t('quizRoute')}><h1>{t('navBarHeaderQuiz')}</h1></CustomLink>
                <LanguageChooser/>
            </DesktopMenu>
        </StyledNavbar>
    )

}

export default Navbar;