import {StyledHeaderLogo} from "../styledComponents/Image.style";
import logoHSE from "../assets/images/hseLogo.png";
import Navbar from "./Navbar";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const header = {
    position:"fixed",
    top: "0",
    width: '110%',
    backgroundColor: 'white',
    marginLeft: "-1em",
    boxShadow: '0 2px 20px black',
    zIndex: 2,
}

const headerContent = {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom:'0',
    display: 'flex',
    flexDirection: 'row',
    boxShadow: 'black'
}

const headerLink = {
    width: '10%',
}

function Header (){

    const {t} = useTranslation()
    return(
        <>
            <section style={header}>
                <div style={headerContent}>
                  <Link style={headerLink} to={t('homeRoute')}>
                      <StyledHeaderLogo src={logoHSE} alt={'Logo HSE365'}/>
                  </Link>
                    <Navbar/>
                </div>
            </section>
        </>
    )
}

export default Header;