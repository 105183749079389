import styled from "styled-components";

export const StyledHeaderLogo = styled.img`
    //width: 150%;
  width: 200px;
    padding: 14px 0 13px 0;
    margin: 1px 0 auto 5px;
    //margin-left: 15%;

  @media (max-width: 1110px){
  margin-top: 1em;
    
  }
  
  @media (max-width: 600px) {
    width: 90px;
   margin: 0.4em auto auto auto;
  }
`

export const StyledPageBanner = styled.img`

  width: 90%;
  // height: ${props => props.height} px;
 
  @media (max-width: 600px) {
    margin-top: -0.7em;
    height: auto;
  }
`



export const StyledCertificateImage = styled.img`
  margin: 4em auto;
  box-shadow: 3px 3px 20px 2px gray;
  width: 50%;
  display: block;
`;
